import { createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Work Sans',
    h1: {
      fontSize: '64px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
    },
  },
});

export default theme;
