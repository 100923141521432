export const secondaryList = [
  'African and African American Studies',
  'Anthropology',
  'Archaeology',
  'Astrophysics',
  'Celtic Languages and Literatures',
  'Chemistry',
  'Classics',
  'Comparative Literature',
  'Comparative Study of Religion',
  'Computer Science',
  'Earth and Planetary Sciences',
  'East Asian Studies',
  'Economics',
  'Education Studies',
  'Energy and Environment',
  'English',
  'Environmental Science and Public Policy',
  'Environmental Studies',
  'Ethnicity, Migration, Rights',
  'European History, Politics, and Societies',
  'Folklore and Mythology',
  'German and Scandinavian Studies',
  'Global Health and Health Policy',
  'Government',
  'History',
  'History of Art and Architecture',
  'History of Science',
  'Human Evolutionary Biology',
  'Integrative Biology',
  'Latino Studies',
  'Lesbian, Gay, Bisexual, and Transgender Studies',
  'Linguistics',
  'Mathematical Sciences',
  'Medieval Studies',
  'Microbial Sciences',
  'Mind, Brain, Behavior',
  'Molecular and Cellular Biology',
  'Music',
  'Near Eastern Languages and Civilizations',
  'Neuroscience',
  'Philosophy',
  'Physics',
  'Psychology',
  'Romance Languages and Literatures',
  'Russia, Eastern Europe, and Central Asia',
  'Slavic Languages and Literatures',
  'Sociology',
  'South Asian Studies',
  'Statistics',
  'Studies of Women, Gender, and Sexuality',
  'Theater, Dance, and Media',
  'Art, Film, and Visual Studies',
  'Translation Studies',
];
