import { Stack, ListItem, Typography } from '@mui/material';

function NotFound() {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ width: 1, height: '100vh' }}
    >
      <ListItem>
        <Typography variant="h1">Page Not Found</Typography>
      </ListItem>

      <ListItem>
        <Typography variant="h6">
          It looks like you landed on a page that you weren&apos;t supposed to
          be on. Please navigate back to our main website{' '}
          <a href="https://harvardyearbook.com">here</a>.
        </Typography>
      </ListItem>
    </Stack>
  );
}

export default NotFound;
