export const houseActivitiesList = [
  'All-House Committee',
  'Co-Op',
  'House Formal Hall',
  'House Advising Peer',
  'House Arts Society',
  'House Chamber Ensemble',
  'House Committee',
  'House Dark Room',
  'House Drama Society',
  'House Facebook',
  'House Film Society',
  'House Music Society',
  'House Musical',
  'House Tea',
  'House Theatre Board',
  'House Town Crier',
  'House Webmaster',
  'Intramurals',
  "Hare's Kitchen",
];
