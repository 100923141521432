import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CloudinaryUploadWidget from 'src/components/CloudinaryUploadWidget';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';

const templateNames: string[] = [
  'Silver #1',
  'Silver #2',
  'Silver #3',
  'Silver #4',
  'Silver #5',
  'Silver #6',
  'Platinum #1',
  'Platinum #2',
  'Platinum #3',
  'Platinum #4',
  'Platinum #5',
  'Platinum #6',
  'Platinum #7',
  'Crimson #1',
  'Crimson #2',
  'Crimson #3',
  'Crimson #4',
  'Crimson #5',
  'Crimson #6',
  'Crimson #7',
  'Crimson #8',
  'Crimson #9',
  'John Harvard #1',
  'John Harvard #2',
  'John Harvard #3',
];

const imagesByTemplates: number[] = [
  1, 1, 1, 1, 1, 2, 1, 2, 1, 1, 2, 4, 4, 1, 2, 8, 8, 2, 10, 10, 4, 4, 10, 6, 1,
];

function ParentAdSubmission() {
  const navigate = useNavigate();
  const [searchParams, _setSearchParams] = useSearchParams();

  const [selectedTemplate, setSelectedTemplate] = useState<number | null>(null);
  const [content, setContent] = useState<string>('');
  const [specialRequests, setSpecialRequests] = useState<string>('');

  const [submittedPhotos, setSubmittedPhotos] = useState<string[]>([]);

  const [submittingInfo, setSubmittingInfo] = useState<boolean>(false);

  const parentAdId = searchParams.get('id');
  const [studentName, setStudentName] = useState<string>('');

  const setPhotoLink = (photoLink: string, index?: number) => {
    if (index) {
      setSubmittedPhotos(
        submittedPhotos.map((photo, ind) =>
          ind === index ? photoLink : photo,
        ),
      );
    }
  };

  useEffect(() => {
    if (parentAdId && parentAdId !== '') {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/parent_ads/${parentAdId}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 'success') {
            setStudentName(data.data.studentName);
          } else {
            // eslint-disable-next-line no-alert
            alert('There was an error fetching your information');
          }
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          alert('There was an error fetching your information');
        });
    }
  }, [parentAdId]);

  const submitBioform = () => {
    if (submittingInfo) return;
    setSubmittingInfo(true);

    const validators = {
      selectedTemplate: selectedTemplate !== null,
      submittedPhotos: submittedPhotos.length > 0,
    };

    if (!Object.values(validators).every((v) => v)) {
      // eslint-disable-next-line no-alert
      alert('Please fill out all required fields');
      setSubmittingInfo(false);
      return;
    }

    fetch(`${process.env.REACT_APP_BACKEND_URL}/freshman_register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        parentAdId,
        selectedTemplate,
        submittedPhotos,
        content,
        specialRequests,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          if (data.data.url !== '') {
            window.location = data.data.url;
          } else {
            navigate('/success', { replace: true });
          }
        } else {
          // eslint-disable-next-line no-alert
          alert('There was an error submitting your information');
        }
        setSubmittingInfo(false);
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert(
          'There was an error submitting your information. Please try again later.',
        );
        setSubmittingInfo(false);
      });
  };

  return (
    <>
      <Header />

      <Box sx={{ display: 'flex', minHeight: 'calc(100vh - 176px)' }}>
        <Box
          sx={{
            pl: 4,
            width: '300px',
            pt: 4,
          }}
        >
          {studentName && studentName !== '' && (
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    pr: 1,
                  }}
                >
                  Select Template:
                </Typography>

                <Select
                  required
                  displayEmpty
                  value={selectedTemplate}
                  onChange={(e) => {
                    if (e.target.value === null) {
                      return setSelectedTemplate(null);
                    }

                    const newTemplate = parseInt(
                      JSON.stringify(e.target.value),
                      10,
                    );

                    if (
                      selectedTemplate &&
                      imagesByTemplates[newTemplate - 1] !==
                        imagesByTemplates[selectedTemplate - 1]
                    ) {
                      const oldNumber = imagesByTemplates[selectedTemplate - 1];
                      const newNumber = imagesByTemplates[newTemplate - 1];

                      if (oldNumber > newNumber) {
                        setSubmittedPhotos(submittedPhotos.slice(0, newNumber));
                      } else {
                        setSubmittedPhotos([
                          ...submittedPhotos,
                          ...new Array(newNumber - oldNumber).fill(
                            '',
                            0,
                            newNumber - oldNumber,
                          ),
                        ]);
                      }
                    }

                    return setSelectedTemplate(newTemplate);
                  }}
                >
                  {templateNames.map((name, ind) => (
                    <MenuItem value={ind + 1} key={ind + 1}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              {selectedTemplate &&
                Array.from(
                  { length: imagesByTemplates[selectedTemplate - 1] },
                  (_, i) => i,
                ).map((num) => (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        pr: 1,
                      }}
                    >
                      Insert Image {JSON.stringify(num + 1)}:
                    </Typography>
                    <CloudinaryUploadWidget
                      uwConfig={{
                        cloudName: 'harvard-yearbook',
                        uploadPreset: 'b266p2uw',
                        folder: 'groups_389',
                      }}
                      fileName={studentName}
                      openWidget={studentName !== ''}
                      setPhotoLink={setPhotoLink}
                      index={num}
                    />
                    {submittedPhotos[num] && submittedPhotos[num] !== '' && (
                      <Typography>
                        Image Link: {submittedPhotos[num]}
                      </Typography>
                    )}
                  </Grid>
                ))}

              <Grid item xs={12}>
                <Typography>Content:</Typography>
                <TextField
                  multiline
                  label="Content"
                  rows={4}
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography>Special Requests:</Typography>
                <TextField
                  label="Special Requests"
                  value={specialRequests}
                  onChange={(e) => setSpecialRequests(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Button fullWidth variant="contained" onClick={submitBioform}>
                  {submittingInfo ? <CircularProgress /> : 'Submit'}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>

      <Footer />
    </>
  );
}

export default ParentAdSubmission;
