import { Box, Grid, Typography } from '@mui/material';

function Header() {
  return (
    <Box
      sx={{
        backgroundColor: '#A51C30',
        width: '100%',
        height: '100px',
      }}
    >
      <Box
        sx={{
          padding: '14px 40px',
          height: 'calc(100% - 28px)',
        }}
      >
        <img
          src="/hyp-logo-white.png"
          alt="Harvard Yearbook Publications"
          width="70px"
          height="73px"
          style={{
            float: 'left',
          }}
        />
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          columnSpacing={2}
          sx={{
            color: 'white',
            float: 'right',
            height: '100%',
            width: 'auto',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Grid item>
            <Typography variant="body1">
              <a
                href="https://harvardyearbook.com/"
                style={{
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Home
              </a>
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1">
              <a
                href="/"
                style={{
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Forms
              </a>
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1">
              <a
                href="/staff"
                style={{
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Staff
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Header;
