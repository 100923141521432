import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import { countryList } from 'src/constants/countryList';
import { statesList } from 'src/constants/stateList';
import dayjs, { Dayjs } from 'dayjs';
import ClearIcon from '@mui/icons-material/Clear';
import { houseList } from 'src/constants/houseList';
import { concentrationList } from 'src/constants/concentrationList';
import { secondaryList } from 'src/constants/secondaryList';
import { languageCitationList } from 'src/constants/languageCitationList';
import { honorsList } from 'src/constants/honorsList';
import { intercollegiateSportsList } from 'src/constants/intercollegiateSportsList';
import { houseActivitiesList } from 'src/constants/houseActivities';
import { harvardActivitiesList } from 'src/constants/harvardActivitiesList';
import { clubSportsList } from 'src/constants/clubSportsList';
import { onCampusJobsList } from 'src/constants/onCampusJobsList';

interface ExtracurricularPosition {
  club: string;
  position: string;
}

function YearbookBioform() {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState<string>('');
  const [middleName, setMiddleName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [suffix, setSuffix] = useState<string>('');

  const [house, setHouse] = useState<number>(1);
  const [classYear, setClassYear] = useState<number>(2025);

  const [email, setEmail] = useState<string>('');
  const [alternateEmail, setAlternateEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const [studyType, setStudyType] = useState<number>(1);
  const [concentration, setConcentration] = useState<string>('');
  const [secondConcentration, setSecondConcentration] = useState<string>('');
  const [secondary, setSecondary] = useState<string>('');
  const [languageCitation, setLanguageCitation] = useState<string[]>([]);
  const [specialConcentration, setSpecialConcentration] = useState<string>('');

  const [professorFirstName, setProfessorFirstName] = useState<string>('');
  const [professorLastName, setProfessorLastName] = useState<string>('');
  const [professorDepartment, setProfessorDepartment] = useState<string>('');
  const [professorEmail, setProfessorEmail] = useState<string>('');

  const [birthDate, setBirthDate] = useState<Dayjs | null>(null);

  const [country, setCountry] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [city, setCity] = useState<string>('');

  const [secondarySchool, setSecondarySchool] = useState<string>('');

  const [academicHonors, setAcademicHonors] = useState<string[]>([]);

  const [intercollegiateSportsName, setIntercollegiateSportsName] = useState<
    string | null
  >(null);
  const [intercollegiateSportsPosition, setIntercollegiateSportsPosition] =
    useState<string>('');
  const [intercollegiateSports, setIntercollegiateSports] = useState<
    ExtracurricularPosition[]
  >([]);

  const [houseActivitiesName, setHouseActivitiesName] = useState<string | null>(
    null,
  );
  const [houseActivitiesPosition, setHouseActivitiesPosition] =
    useState<string>('');
  const [houseActivities, setHouseActivities] = useState<
    ExtracurricularPosition[]
  >([]);

  const [harvardActivitiesName, setHarvardActivitiesName] = useState<
    string | null
  >(null);
  const [harvardActivitiesPosition, setHarvardActivitiesPosition] =
    useState<string>('');
  const [harvardActivities, setHarvardActivities] = useState<
    ExtracurricularPosition[]
  >([]);

  const [clubSportsName, setClubSportsName] = useState<string | null>(null);
  const [clubSportsPosition, setClubSportsPosition] = useState<string>('');
  const [clubSports, setClubSports] = useState<ExtracurricularPosition[]>([]);

  const [onCampusJobsName, setOnCampusJobsName] = useState<string | null>(null);
  const [onCampusJobsPosition, setOnCampusJobsPosition] = useState<string>('');
  const [onCampusJobs, setOnCampusJobs] = useState<ExtracurricularPosition[]>(
    [],
  );

  const [submittingInfo, setSubmittingInfo] = useState<boolean>(false);

  const formattedSuffix = useMemo(() => {
    if (suffix === '') return '';
    if (
      suffix.toLowerCase().includes('jr') ||
      suffix.toLowerCase().includes('junior')
    )
      return `, Jr.`;
    if (
      suffix.toLowerCase().includes('sr') ||
      suffix.toLowerCase().includes('senior')
    )
      return `, Sr.`;
    return ` ${suffix}`;
  }, [suffix]);

  const finalName = useMemo(
    () =>
      `${firstName.trim()} ${middleName.trim()} ${lastName.trim()}${formattedSuffix}`,
    [firstName, middleName, lastName, formattedSuffix],
  );

  const finalBioform = useMemo(
    () =>
      `${birthDate ? `Born on: ${birthDate.format('MMMM DD, YYYY')}. ` : ''}${
        secondarySchool !== '' ? `Secondary School: ${secondarySchool}. ` : ''
      }${
        // eslint-disable-next-line no-nested-ternary
        country !== '' && city !== ''
          ? country === 'United States'
            ? `Hometown: ${city}, ${state}. `
            : `Hometown: ${city}, ${country}. `
          : ''
      }${
        concentration !== '' || specialConcentration !== ''
          ? `Field of Concentration: ${
              // eslint-disable-next-line no-nested-ternary
              studyType === 1
                ? `${concentrationList[parseInt(concentration, 10) - 1]}. `
                : // eslint-disable-next-line no-nested-ternary
                  studyType === 4
                  ? `${specialConcentration}. `
                  : studyType === 2
                    ? `${concentrationList[parseInt(concentration, 10) - 1]} & ${concentrationList[parseInt(secondConcentration, 10) - 1]}. `
                    : `${concentrationList[parseInt(concentration, 10) - 1]} and ${concentrationList[parseInt(secondConcentration, 10) - 1]}. `
            }`
          : ''
      }${secondary ? `Secondary: ${secondaryList[parseInt(secondary, 10) - 1]}. ` : ''}${
        languageCitation.length > 0
          ? `Language Citations: ${languageCitation.join(', ')}. `
          : ''
      }${academicHonors.length > 0 ? `${academicHonors.join('. ')}. ` : ''}${
        intercollegiateSports.length > 0
          ? intercollegiateSports
              .map(
                (sport) =>
                  `${sport.club}${
                    sport.position !== '' ? ` (${sport.position})` : ''
                  }`,
              )
              .join('. ')
              .concat('. ')
          : ''
      }${
        houseActivities.length > 0
          ? houseActivities
              .map(
                (activity) =>
                  `${activity.club}${
                    activity.position !== '' ? ` (${activity.position})` : ''
                  }`,
              )
              .join('. ')
              .concat('. ')
          : ''
      }${
        harvardActivities.length > 0
          ? harvardActivities
              .map(
                (activity) =>
                  `${activity.club}${
                    activity.position !== '' ? ` (${activity.position})` : ''
                  }`,
              )
              .join('. ')
              .concat('. ')
          : ''
      }${
        clubSports.length > 0
          ? clubSports
              .map(
                (activity) =>
                  `${activity.club}${
                    activity.position !== '' ? ` (${activity.position})` : ''
                  }`,
              )
              .join('. ')
              .concat('. ')
          : ''
      }${
        onCampusJobs.length > 0
          ? onCampusJobs
              .map(
                (activity) =>
                  `${activity.club}${
                    activity.position !== '' ? ` (${activity.position})` : ''
                  }`,
              )
              .join('. ')
              .concat('. ')
          : ''
      }`.trim(),
    [
      birthDate,
      secondarySchool,
      country,
      state,
      city,
      studyType,
      concentration,
      specialConcentration,
      secondConcentration,
      secondary,
      languageCitation,
      academicHonors,
      intercollegiateSports,
      houseActivities,
      harvardActivities,
      clubSports,
      onCampusJobs,
    ],
  );

  const submitBioform = () => {
    if (submittingInfo) return;
    setSubmittingInfo(true);

    const validators = {
      firstName: firstName.length > 0,
      lastName: lastName.length > 0,
      house: house > 0 && house <= houseList.length,
      classYear: classYear >= 2022 && classYear <= 2025,
      email: email.length > 0,
      alternateEmail: alternateEmail.length > 0,
      phone: phone.length > 0,

      studyType: studyType >= 1 && studyType <= 4,
      concentration:
        studyType >= 1 && studyType <= 3 ? concentration !== '' : true,
      secondConcentration:
        studyType === 2 || studyType === 3 ? secondConcentration !== '' : true,
      specialConcentration:
        studyType === 4 ? specialConcentration.length > 0 : true,
    };

    if (!Object.values(validators).every((v) => v)) {
      // eslint-disable-next-line no-alert
      alert('Please fill out all required fields');
      setSubmittingInfo(false);
      return;
    }

    fetch(`${process.env.REACT_APP_BACKEND_URL}/yearbook/bioform`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName,
        middleName,
        lastName,
        suffix,
        house: houseList[house - 1],
        classYear,
        email,
        alternateEmail,
        phone,
        studyType,
        concentration: concentrationList[parseInt(concentration, 10) - 1],
        secondConcentration:
          concentrationList[parseInt(secondConcentration, 10) - 1],
        secondary: secondaryList[parseInt(secondary, 10) - 1],
        languageCitation,
        specialConcentration,
        professorFirstName,
        professorLastName,
        professorDepartment,
        professorEmail,
        birthDate,
        country,
        state,
        city,
        secondarySchool,
        academicHonors,
        intercollegiateSports,
        houseActivities,
        harvardActivities,
        clubSports,
        onCampusJobs,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          if (data.data.url && data.data.url !== '') {
            window.location = data.data.url;
          } else {
            navigate('/success_bioform', { replace: true });
          }
        } else {
          // eslint-disable-next-line no-alert
          alert('There was an error submitting your information');
        }
        setSubmittingInfo(false);
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert(
          'There was an error submitting your information. Please try again later.',
        );
        setSubmittingInfo(false);
      });
  };

  return (
    <>
      <Header />

      <Box
        sx={{
          width: '100%',
          pt: 4,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1">Yearbook Bioforms</Typography>
      </Box>

      <Box
        sx={{
          pl: '10%',
          width: '80%',
          pt: 4,
          display: 'grid',
        }}
      >
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Congratulations on your upcoming graduation from Harvard!
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Please fill out the form below in order to include your
              biographical information in the 2024 Harvard Yearbook.{' '}
              <b>
                Note that we do not publish biographical information without a
                senior portrait: you will NOT appear in the yearbook unless you
                have a photo taken.
              </b>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              If you need to modify your information after you have submitted
              the form, please submit this form again and your new information
              will replace what is already in our database.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              We cannot be held responsible for information that you type
              incorrectly. Please write with appropriate capitalization; do not
              write in all caps or in all lowercase.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              The deadline for submitting information is{' '}
              <b>October 22nd, 2025 11:59PM</b>.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Student Information</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="first-name"
              label="Student's First Name"
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="middle-name"
              label="Student's Middle Name"
              variant="outlined"
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="last-name"
              label="Student's Last Name"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="suffix"
              label="Student's Suffix (e.g., Jr., III)"
              variant="outlined"
              value={suffix}
              onChange={(e) => setSuffix(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Contact Information</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email (@college.harvard.edu email)"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="alternate-email"
              label="Alternate Email (NOT @college.harvard.edu)"
              variant="outlined"
              value={alternateEmail}
              onChange={(e) => setAlternateEmail(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="phone"
              label="Phone Number"
              variant="outlined"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">House Affiliation</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Your picture and biographical information will appear in the House
              Section that you select as your affiliation. Please make sure you
              select your appropriate house. If you transfer houses, it is your
              responsibility to inform us of this switch ASAP.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <InputLabel id="house-affiliation">House Affiliation *</InputLabel>
            <Select
              required
              fullWidth
              labelId="house-affiliation"
              id="select-house-affiliation"
              label="House Affiliation"
              value={house}
              onChange={(e) =>
                setHouse(parseInt(JSON.stringify(e.target.value), 10))
              }
            >
              {houseList.map((houseName, ind) => (
                <MenuItem key={houseName} value={ind + 1}>
                  {houseName}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="class-year"
              label="Class Year"
              variant="outlined"
              value={classYear}
              onChange={(e) => setClassYear(parseInt(e.target.value, 10))}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Field of Study</Typography>
          </Grid>

          <Grid item xs={12}>
            <InputLabel id="study-type">Study Type *</InputLabel>
            <Select
              required
              fullWidth
              labelId="study-type"
              id="select-study-type"
              label="Type"
              value={studyType}
              onChange={(e) =>
                setStudyType(parseInt(JSON.stringify(e.target.value), 10))
              }
            >
              <MenuItem value={1}>Regular Concentration</MenuItem>
              <MenuItem value={2}>Joint Concentration</MenuItem>
              <MenuItem value={3}>Double Concentration</MenuItem>
              <MenuItem value={4}>Special/Other Concentration</MenuItem>
            </Select>
          </Grid>

          {studyType !== 4 && (
            <Grid item xs={12}>
              <InputLabel id="concentration">
                Primary Concentration *
              </InputLabel>
              <Select
                required
                fullWidth
                labelId="concentration"
                id="select-concentration"
                label="Concentration"
                value={concentration}
                onChange={(e) => setConcentration(e.target.value)}
              >
                {concentrationList.map((selectConcentration, ind) => (
                  <MenuItem key={selectConcentration} value={ind + 1}>
                    {selectConcentration}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}

          {(studyType === 2 || studyType === 3) && (
            <Grid item xs={12}>
              <InputLabel id="concentration">
                {studyType === 2 ? 'Joint' : 'Double'} Concentration *
              </InputLabel>
              <Select
                required
                fullWidth
                labelId="concentration"
                id="select-concentration"
                label="Second/Joint Concentration"
                value={secondConcentration}
                onChange={(e) => setSecondConcentration(e.target.value)}
              >
                {concentrationList.map((selectConcentration, ind) => (
                  <MenuItem key={selectConcentration} value={ind + 1}>
                    {selectConcentration}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}

          {studyType === 4 && (
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="special-concentration"
                label="Special Concentration Name"
                variant="outlined"
                value={specialConcentration}
                onChange={(e) => setSpecialConcentration(e.target.value)}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <InputLabel id="secondary">Secondary</InputLabel>
            <Select
              required
              fullWidth
              labelId="secondary"
              id="select-secondary"
              label="Secondary"
              value={secondary}
              onChange={(e) => setSecondary(e.target.value)}
            >
              {secondaryList.map((selectSecondary, ind) => (
                <MenuItem key={selectSecondary} value={ind + 1}>
                  {selectSecondary}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12}>
            <InputLabel id="language-citations">Language Citations</InputLabel>
            <Select
              required
              fullWidth
              multiple
              labelId="language-citations"
              id="select-language-citations"
              value={languageCitation}
              renderValue={(selected) => selected.join(', ')}
              onChange={(e) => {
                setLanguageCitation(
                  typeof e.target.value === 'string'
                    ? e.target.value.split(',')
                    : e.target.value,
                );
              }}
            >
              {languageCitationList.map((citation) => (
                <MenuItem key={citation} value={citation}>
                  <Checkbox checked={languageCitation.indexOf(citation) > -1} />
                  <ListItemText primary={citation} />
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Favorite Professor</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Vote for your favorite professor; the most popular professors will
              be asked to contribute a letter to your yearbook, but your vote
              will not be included alongside your bioform.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="professor-first-name"
              label="Professors's First Name"
              variant="outlined"
              value={professorFirstName}
              onChange={(e) => setProfessorFirstName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="professor-last-name"
              label="Professor's Last Name"
              variant="outlined"
              value={professorLastName}
              onChange={(e) => setProfessorLastName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="professor-department"
              label="Professor's Department"
              variant="outlined"
              value={professorDepartment}
              onChange={(e) => setProfessorDepartment(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="professor-email"
              label="Professor's Email"
              variant="outlined"
              value={professorEmail}
              onChange={(e) => setProfessorEmail(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Date of Birth</Typography>
          </Grid>

          <Grid item xs={12}>
            <DatePicker
              value={birthDate}
              onChange={(newValue) => setBirthDate(dayjs(newValue))}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Hometown</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Please enter your hometown information.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <InputLabel required id="country">
              Country
            </InputLabel>
            <Select
              fullWidth
              id="country"
              label-id="country"
              variant="outlined"
              value={country}
              onChange={(e: SelectChangeEvent<string>) =>
                setCountry(e.target.value)
              }
            >
              {Object.keys(countryList).map((countryName) => (
                <MenuItem key={countryName} value={countryName}>
                  {countryName}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {country === 'United States' && (
            <Grid item xs={12}>
              <InputLabel id="state">State</InputLabel>
              <Select
                fullWidth
                displayEmpty
                id="state"
                label-id="state"
                variant="outlined"
                value={state}
                onChange={(e: SelectChangeEvent<string>) =>
                  setState(e.target.value)
                }
              >
                {Object.keys(statesList).map((stateName) => (
                  <MenuItem key={stateName} value={stateName}>
                    {stateName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="city"
              label="City"
              variant="outlined"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Secondary School</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Please enter the secondary school from which you graduated.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="secondary-school"
              label="School Name"
              variant="outlined"
              value={secondarySchool}
              onChange={(e) => setSecondarySchool(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Academic Honors</Typography>
          </Grid>

          <Grid item xs={12}>
            <InputLabel id="academic-honors">Academic Honors</InputLabel>
            <Select
              required
              fullWidth
              multiple
              labelId="academic-honors"
              id="select-academic-honors"
              value={academicHonors}
              renderValue={(selected) => selected.join(', ')}
              onChange={(e) => {
                setAcademicHonors(
                  typeof e.target.value === 'string'
                    ? e.target.value.split(',')
                    : e.target.value,
                );
              }}
            >
              {honorsList.map((honor) => (
                <MenuItem key={honor} value={honor}>
                  <Checkbox checked={academicHonors.indexOf(honor) > -1} />
                  <ListItemText primary={honor} />
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Extracurriculars</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Select the activities in which you were involved in order of
              importance from the drop-down menus below. If applicable, type in
              the office or leadership position that you held.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Only specific officer positions should be listed. (Do not write
              &quot;Member&quot; or &quot;Staff Writer&quot;!) If you held
              multiple offices in an organization,{' '}
              <b>please separate each with semicolons</b>. Titles should be
              capitalized. Do not add the same activity multiple times. Only one
              will be kept and the rest will be deleted.{' '}
              <b>Do not include the years you held a specific position.</b>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Due to publication constraints, the word &quot;Harvard&quot; will
              be included in the name of the student group ONLY if the activity
              is a media or publication.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              If you encounter any problems, please email us at{' '}
              <a href="mailto:inquiries@harvardyearbook.com">
                inquiries@harvardyearbook.com
              </a>
              .
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Intercollegiate Sports</Typography>
          </Grid>

          {intercollegiateSports.length > 0 && (
            <Grid item xs={12}>
              <List
                sx={{
                  padding: 0,
                }}
              >
                {intercollegiateSports.map((sport, ind) => (
                  <ListItem key={ind}>
                    <ListItemIcon
                      onClick={(_e) =>
                        setIntercollegiateSports(
                          intercollegiateSports.filter((_, i) => i !== ind),
                        )
                      }
                    >
                      <ClearIcon
                        style={{
                          cursor: 'pointer',
                          color: 'red',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={sport.club}
                      secondary={sport.position}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}

          <Grid container item xs={12} columnSpacing={1}>
            <Grid item xs={5.5}>
              <Autocomplete
                freeSolo
                value={intercollegiateSportsName}
                getOptionDisabled={(option) =>
                  intercollegiateSports.map((i) => i.club).includes(option)
                }
                onChange={(_e, newValue) =>
                  setIntercollegiateSportsName(newValue)
                }
                options={intercollegiateSportsList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="intercollegiate-sports-name"
                    label="Intercollegiate Sport"
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={5.5}>
              <TextField
                fullWidth
                id="intercollegiate-sports-position"
                label="Officer/Leadership Position"
                variant="outlined"
                value={intercollegiateSportsPosition}
                onChange={(e) =>
                  setIntercollegiateSportsPosition(e.target.value)
                }
              />
            </Grid>

            <Grid item xs={1}>
              <Button
                fullWidth
                variant="contained"
                disabled={
                  intercollegiateSportsName === '' ||
                  intercollegiateSportsName === null
                }
                onClick={() => {
                  if (
                    intercollegiateSportsName === '' ||
                    intercollegiateSportsName === null
                  )
                    return;

                  if (
                    intercollegiateSportsList.indexOf(
                      intercollegiateSportsName,
                    ) === -1
                  )
                    return;

                  setIntercollegiateSports([
                    ...intercollegiateSports,
                    {
                      club: intercollegiateSportsName,
                      position: intercollegiateSportsPosition,
                    },
                  ]);
                  setIntercollegiateSportsName('');
                  setIntercollegiateSportsPosition('');
                }}
                sx={{
                  height: '100%',
                }}
              >
                Add
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">House Activities</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Note: For house activities, attending or participating in an event
              does not imply membership; only list a house event if you were
              specifically involved in planning or leading it. Please do not
              include the name of your House in these activities; your name and
              bioform will already be listed under your House.
            </Typography>
          </Grid>

          {houseActivities.length > 0 && (
            <Grid item xs={12}>
              <List
                sx={{
                  padding: 0,
                }}
              >
                {houseActivities.map((activity, ind) => (
                  <ListItem key={ind}>
                    <ListItemIcon
                      onClick={(_e) =>
                        setHouseActivities(
                          houseActivities.filter((_, i) => i !== ind),
                        )
                      }
                    >
                      <ClearIcon
                        style={{
                          cursor: 'pointer',
                          color: 'red',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={activity.club}
                      secondary={activity.position}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}

          <Grid container item xs={12} columnSpacing={1}>
            <Grid item xs={5.5}>
              <Autocomplete
                freeSolo
                value={houseActivitiesName}
                getOptionDisabled={(option) =>
                  houseActivities.map((i) => i.club).includes(option)
                }
                onChange={(_e, newValue) => setHouseActivitiesName(newValue)}
                options={houseActivitiesList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="house-activities-name"
                    label="House Activities"
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={5.5}>
              <TextField
                fullWidth
                id="house-activities-position"
                label="Officer/Leadership Position"
                variant="outlined"
                value={houseActivitiesPosition}
                onChange={(e) => setHouseActivitiesPosition(e.target.value)}
              />
            </Grid>

            <Grid item xs={1}>
              <Button
                fullWidth
                variant="contained"
                disabled={
                  houseActivitiesName === '' || houseActivitiesName === null
                }
                onClick={() => {
                  if (
                    houseActivitiesName === '' ||
                    houseActivitiesName === null
                  )
                    return;

                  if (houseActivitiesList.indexOf(houseActivitiesName) === -1)
                    return;

                  setHouseActivities([
                    ...houseActivities,
                    {
                      club: houseActivitiesName,
                      position: houseActivitiesPosition,
                    },
                  ]);
                  setHouseActivitiesName('');
                  setHouseActivitiesPosition('');
                }}
                sx={{
                  height: '100%',
                }}
              >
                Add
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Harvard Activities</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Due to College policy, we may include only organizations that are
              registered with the{' '}
              <a
                href="https://osl.fas.harvard.edu/student-organizations"
                target="_blank"
                rel="noreferrer"
              >
                Dean of Students Office
              </a>
              .
            </Typography>

            <Typography variant="body1">
              For pre-orientation programs listed, such as First-Year Arts
              Program, select it only if you specifically had a role in planning
              or leading it.
            </Typography>

            <Typography variant="body1">
              All PBHA programs are listed under PBHA. Please do not include
              &quot;Volunteer&quot; as a position.
            </Typography>
          </Grid>

          {harvardActivities.length > 0 && (
            <Grid item xs={12}>
              <List
                sx={{
                  padding: 0,
                }}
              >
                {harvardActivities.map((activity, ind) => (
                  <ListItem key={ind}>
                    <ListItemIcon
                      onClick={(_e) =>
                        setHarvardActivities(
                          harvardActivities.filter((_, i) => i !== ind),
                        )
                      }
                    >
                      <ClearIcon
                        style={{
                          cursor: 'pointer',
                          color: 'red',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={activity.club}
                      secondary={activity.position}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}

          <Grid container item xs={12} columnSpacing={1}>
            <Grid item xs={5.5}>
              <Autocomplete
                freeSolo
                value={harvardActivitiesName}
                getOptionDisabled={(option) =>
                  harvardActivities.map((i) => i.club).includes(option)
                }
                onChange={(_e, newValue) => setHarvardActivitiesName(newValue)}
                options={harvardActivitiesList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="harvard-activities-name"
                    label="Harvard Activities"
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={5.5}>
              <TextField
                fullWidth
                id="harvard-activities-position"
                label="Officer/Leadership Position"
                variant="outlined"
                value={harvardActivitiesPosition}
                onChange={(e) => setHarvardActivitiesPosition(e.target.value)}
              />
            </Grid>

            <Grid item xs={1}>
              <Button
                fullWidth
                variant="contained"
                disabled={
                  harvardActivitiesName === '' || harvardActivitiesName === null
                }
                onClick={() => {
                  if (
                    harvardActivitiesName === '' ||
                    harvardActivitiesName === null
                  )
                    return;

                  if (
                    harvardActivitiesList.indexOf(harvardActivitiesName) === -1
                  )
                    return;

                  setHarvardActivities([
                    ...harvardActivities,
                    {
                      club: harvardActivitiesName,
                      position: harvardActivitiesPosition,
                    },
                  ]);
                  setHarvardActivitiesName('');
                  setHarvardActivitiesPosition('');
                }}
                sx={{
                  height: '100%',
                }}
              >
                Add
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Club Sports</Typography>
          </Grid>

          {clubSports.length > 0 && (
            <Grid item xs={12}>
              <List
                sx={{
                  padding: 0,
                }}
              >
                {clubSports.map((activity, ind) => (
                  <ListItem key={ind}>
                    <ListItemIcon
                      onClick={(_e) =>
                        setClubSports(clubSports.filter((_, i) => i !== ind))
                      }
                    >
                      <ClearIcon
                        style={{
                          cursor: 'pointer',
                          color: 'red',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={activity.club}
                      secondary={activity.position}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}

          <Grid container item xs={12} columnSpacing={1}>
            <Grid item xs={5.5}>
              <Autocomplete
                freeSolo
                value={clubSportsName}
                getOptionDisabled={(option) =>
                  clubSports.map((i) => i.club).includes(option)
                }
                onChange={(_e, newValue) => setClubSportsName(newValue)}
                options={clubSportsList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="club-sports-name"
                    label="Club Sports"
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={5.5}>
              <TextField
                fullWidth
                id="club-sports-position"
                label="Officer/Leadership Position"
                variant="outlined"
                value={clubSportsPosition}
                onChange={(e) => setClubSportsPosition(e.target.value)}
              />
            </Grid>

            <Grid item xs={1}>
              <Button
                fullWidth
                variant="contained"
                disabled={clubSportsName === '' || clubSportsName === null}
                onClick={() => {
                  if (clubSportsName === '' || clubSportsName === null) return;

                  if (clubSportsList.indexOf(clubSportsName) === -1) return;

                  setClubSports([
                    ...clubSports,
                    {
                      club: clubSportsName,
                      position: clubSportsPosition,
                    },
                  ]);
                  setClubSportsName('');
                  setClubSportsPosition('');
                }}
                sx={{
                  height: '100%',
                }}
              >
                Add
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">On-Campus Jobs</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Harvard Yearbook now accepts on-campus jobs for inclusion in
              senior bioforms.
            </Typography>

            <Typography variant="body1">
              This includes on-campus employment and research during the school
              year only. The employment or research needs to be affiliated with
              Harvard University and on one of the campuses (not strictly the
              College, but any School - for example, research at the Medical
              School or Kennedy School would count too, but MGH or BWH would not
              unless otherwise affiliated with Harvard).
            </Typography>

            <Typography variant="body1">
              For research employment, select &quot;Research.&quot; In addition,
              enter only your lab&apos;s or department&apos;s name. For TF, CA,
              and CF positions, enter the position that you were and then enter
              the corresponding department, but do not enter the name of the
              course(s) or the word &quot;Department.&quot; Separate multiple
              lab names or departments with semicolons (e.g. &quot;Statistics;
              Linguistics&quot; or &quot;Smith Lab; Jones Lab; Chemistry&quot;).
              Otherwise, do not enter your job title.
            </Typography>

            <Typography variant="body1">
              If your place of employment does not appear in the following list,
              please email us at{' '}
              <a href="emailto:inquiries@harvardyearbook.com">
                inquiries@harvardyearbook.com
              </a>
              .
            </Typography>
          </Grid>

          {onCampusJobs.length > 0 && (
            <Grid item xs={12}>
              <List
                sx={{
                  padding: 0,
                }}
              >
                {onCampusJobs.map((activity, ind) => (
                  <ListItem key={ind}>
                    <ListItemIcon
                      onClick={(_e) =>
                        setOnCampusJobs(
                          onCampusJobs.filter((_, i) => i !== ind),
                        )
                      }
                    >
                      <ClearIcon
                        style={{
                          cursor: 'pointer',
                          color: 'red',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={activity.club}
                      secondary={activity.position}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}

          <Grid container item xs={12} columnSpacing={1}>
            <Grid
              item
              xs={
                onCampusJobsName === 'Research' ||
                onCampusJobsName === 'Teaching Fellow' ||
                onCampusJobsName === 'Course Assistant' ||
                onCampusJobsName === 'Course Facilitator'
                  ? 5.5
                  : 11
              }
            >
              <Autocomplete
                freeSolo
                value={onCampusJobsName}
                getOptionDisabled={(option) =>
                  onCampusJobs.map((i) => i.club).includes(option)
                }
                onChange={(_e, newValue) => setOnCampusJobsName(newValue)}
                options={onCampusJobsList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="on-campus-jobs-name"
                    label="On-Campus Jobs"
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            {onCampusJobsName === 'Research' && (
              <Grid item xs={5.5}>
                <TextField
                  fullWidth
                  id="on-campus-jobs-position"
                  label="Lab or Department Name, if applicable"
                  variant="outlined"
                  value={onCampusJobsPosition}
                  onChange={(e) => setOnCampusJobsPosition(e.target.value)}
                />
              </Grid>
            )}

            {(onCampusJobsName === 'Teaching Fellow' ||
              onCampusJobsName === 'Course Assistant' ||
              onCampusJobsName === 'Course Facilitator') && (
              <Grid item xs={5.5}>
                <TextField
                  fullWidth
                  id="on-campus-jobs-position"
                  label="Department"
                  variant="outlined"
                  value={onCampusJobsPosition}
                  onChange={(e) => setOnCampusJobsPosition(e.target.value)}
                />
              </Grid>
            )}

            <Grid item xs={1}>
              <Button
                fullWidth
                variant="contained"
                disabled={onCampusJobsName === '' || onCampusJobsName === null}
                onClick={() => {
                  if (onCampusJobsName === '' || onCampusJobsName === null)
                    return;

                  if (onCampusJobsList.indexOf(onCampusJobsName) === -1) return;

                  setOnCampusJobs([
                    ...onCampusJobs,
                    {
                      club: onCampusJobsName,
                      position: onCampusJobsPosition,
                    },
                  ]);
                  setOnCampusJobsName('');
                  setOnCampusJobsPosition('');
                }}
                sx={{
                  height: '100%',
                }}
              >
                Add
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Preview</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              You have used <b>{finalBioform.length}</b> out of a maximum{' '}
              <b>525</b> characters.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: '#F5F5F5',
              }}
            >
              <Typography>{finalName}</Typography>
              <Typography>
                {finalBioform.length >= 525
                  ? `${finalBioform.substring(0, 522)}...`
                  : finalBioform}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Note: If there is not enough space in the yearbook to list all
              your activities, Harvard Yearbook will omit the ones at the
              bottom.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Please keep in mind that each bioform is carefully reviewed by our
              staff. Harvard Yearbook Publications reserves the right to edit
              your submission and will not publish biographical information we
              feel is false or inappropriate.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button fullWidth variant="contained" onClick={submitBioform}>
              {submittingInfo ? <CircularProgress /> : 'Submit'}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Footer />
    </>
  );
}

export default YearbookBioform;
