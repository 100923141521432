export const languageCitationList = [
  'American Sign Language',
  'Catalan',
  'Chinese',
  'Classical Arabic',
  'Classical Hebrew',
  'Classical Tibetan',
  'Czech',
  'Danish',
  'Finnish',
  'French',
  'German',
  'Gikuyu',
  'Greek',
  'Hindi-Urdu',
  'Igbo',
  'Italian',
  'Japanese',
  'Korean',
  'Latin',
  'Literary Chinese',
  'Modern Greek',
  'Modern Hebrew',
  'Modern Standard Arabic',
  'Persian',
  'Polish',
  'Portuguese',
  'Russian',
  'Sanskrit',
  'Slavic Languages',
  'Spanish',
  'Swahili',
  'Swedish',
  'Tamil',
  'Turkish',
  'Twi',
  'Ukrainian',
  'Vietnamese',
  'Yiddish',
  'Yoruba',
  'Zulu',
  'Bosnian/Croatian/Serbian',
  'Afrikaans',
  'Thai',
  'Gullah',
  'Amharic',
  'Norwegian',
  'Armenian',
];
