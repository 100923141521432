export const concentrationList = [
  'African and African American Studies',
  'Anthropology',
  'Applied Mathematics',
  'Astrophysics',
  'Biomedical Engineering',
  'Chemical and Physical Biology',
  'Chemistry',
  'Chemistry and Physics',
  'Classics',
  'Comparative Literature',
  'Comparative Study of Religion',
  'Computer Science',
  'Earth and Planetary Sciences',
  'East Asian Studies',
  'Economics',
  'Electrical Engineering',
  'Engineering Sciences',
  'English',
  'Environmental Science and Engineering',
  'Environmental Science and Public Policy',
  'Folklore and Mythology',
  'Germanic Languages and Literatures',
  'Government',
  'History',
  'History and Literature',
  'History and Science',
  'History of Art and Architecture',
  'Human Developmental and Regenerative Biology',
  'Human Evolutionary Biology',
  'Integrative Biology',
  'Linguistics',
  'Literature',
  'Mathematics',
  'Mechanical Engineering',
  'Molecular and Cellular Biology',
  'Music',
  'Near Eastern Languages and Civilizations',
  'Neurobiology',
  'Neuroscience',
  'Philosophy',
  'Physics',
  'Psychology',
  'Romance Languages and Literatures',
  'Slavic Languages and Literatures',
  'Social Studies',
  'Sociology',
  'South Asian Studies',
  'Special Concentrations',
  'Statistics',
  'Studies of Women, Gender, and Sexuality',
  'Theater, Dance, and Media',
  'Art, Film, and Visual Studies',
];
