import { Route, Routes } from 'react-router-dom';
import Staff from 'src/pages/Staff';

function StaffRouter() {
  return (
    <Routes>
      <Route path="/" element={<Staff page="dashboard" />} />
      <Route
        path="/yearbook_orders"
        element={<Staff page="yearbook_orders" />}
      />
      <Route
        path="/freshman_register_orders"
        element={<Staff page="freshman_register_orders" />}
      />
      <Route path="/sales_report" element={<Staff page="sales_report" />} />
      <Route path="/oh_task_list" element={<Staff page="dashboard" />} />
      <Route path="/constants" element={<Staff page="constants" />} />
    </Routes>
  );
}

export default StaffRouter;
