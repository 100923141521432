export const clubSportsList = [
  'Aikikai',
  'Archery Club',
  'Badminton Club',
  'Ballroom Dance Team',
  'Baseball Club',
  'Billiards Club',
  'Bowling Club',
  'Boxing Club',
  "BRed Line [Men's Ultimate Frisbee Club]",
  'Broomball Club',
  'Capoeira Club',
  'Cheerleading',
  'Climbing Club',
  'Cornhole Club',
  'Cricket Club',
  "Crimson Classics [Men's Basketball Club]",
  'Crimson Dance Team',
  'Curling Club',
  'Cycling Club',
  'Dragon Boat',
  'Fencing Club',
  'Field Hockey Club',
  'Figure Skating Club',
  'Flag Football Club',
  'Futsal Club',
  'Golf Club',
  'Hapkido',
  'Harvard Project SWIM Club',
  "Harvard Hoopsters [Men's Basketball Club]",
  'Jiu Jitsu',
  'Kendo',
  'Krav Maga',
  "Men's Ice Hockey Club",
  "Men's Lacrosse Club",
  "Men's Rugby Club",
  "Men's Soccer Club",
  "Men's Tennis Club",
  "Men's Volleyball Club",
  'Nordic Skiing Club',
  'Pistol Club',
  'Polo Club',
  'Powerlifting',
  'Project SWIM',
  'Quidditch Club',
  "Red Line [Men's Ultimate Frisbee Club]",
  'Running Club',
  'Scuba',
  'Shooting Club',
  'Shotokan Karate',
  'Skiing Club',
  'Spikeball Club',
  'Squash Club',
  'Swimming Club',
  'Table Tennis Club',
  'Taekwondo',
  'Tough Mudder',
  'Triathlon',
  'Water Polo Club',
  "Women's Basketball Club",
  "Women's Club Volleyball",
  "Women's Ice Hockey Club",
  "Women's Lacrosse Club",
  "Women's Soccer Club",
  "Women's Tennis Club",
  "Women's Ultimate Frisbee",
  'Wushu',
  'XFit',
  'Sailing Club',
  "Men's Basketball Team",
  "Women's Wrestling Club",
];
