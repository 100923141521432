export const intercollegiateSportsList = [
  "Men's Varsity Alpine Skiing",
  "Men's Varsity Basketball",
  "Men's Varsity Cross Country",
  "Men's Varsity Fencing",
  "Men's Varsity Golf",
  "Men's Varsity Heavyweight Crew",
  "Men's Varsity Ice Hockey",
  "Men's Varsity Lacrosse",
  "Men's Varsity Lightweight Crew",
  "Men's Varsity Nordic Skiing",
  "Men's Varsity Sailing",
  "Men's Varsity Soccer",
  "Men's Varsity Squash",
  "Men's Varsity Swimming and Diving",
  "Men's Varsity Tennis",
  "Men's Varsity Track and Field",
  "Men's Varsity Volleyball",
  "Men's Varsity Water Polo",
  'Varsity Baseball',
  'Varsity Field Hockey',
  'Varsity Football',
  'Varsity Rugby',
  'Varsity Softball',
  'Varsity Wrestling',
  "Women's Varsity Alpine Skiing",
  "Women's Varsity Basketball",
  "Women's Varsity Cross Country",
  "Women's Varsity Fencing",
  "Women's Varsity Golf",
  "Women's Varsity Heavyweight Crew",
  "Women's Varsity Ice Hockey",
  "Women's Varsity Lacrosse",
  "Women's Varsity Lightweight Crew",
  "Women's Varsity Nordic Skiing",
  "Women's Varsity Sailing",
  "Women's Varsity Soccer",
  "Women's Varsity Squash",
  "Women's Varsity Swimming and Diving",
  "Women's Varsity Tennis",
  "Women's Varsity Track and Field",
  "Women's Varsity Volleyball",
  "Women's Varsity Water Polo",
];
