import { ListItem, Stack, Typography } from '@mui/material';

function SuccessBioform() {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ width: 1, height: '100vh' }}
    >
      <ListItem>
        <Typography variant="h1">Bioform Submission Successful</Typography>
      </ListItem>

      <ListItem>
        <Typography variant="h6">
          Please check your email for a confirmation of your submission.
        </Typography>
      </ListItem>
    </Stack>
  );
}

export default SuccessBioform;
