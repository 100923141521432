export const houseList = [
  'Adams House',
  'Cabot House',
  'Currier House',
  'Dudley House',
  'Dunster House',
  'Eliot House',
  'Kirkland House',
  'Leverett House',
  'Lowell House',
  'Mather House',
  'Pforzheimer House',
  'Quincy House',
  'Winthrop House',
];
