import { Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box
      sx={{
        width: '100%',
        height: '60px',
        display: 'flex',
        justifyContent: 'center',
        pt: 2,
      }}
    >
      <Typography variant="body1">
        Created by Nicholas Yang &apos;26 for the Harvard Yearbook Publications
      </Typography>
    </Box>
  );
}

export default Footer;
