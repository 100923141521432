import { ListItem, Stack, Typography } from '@mui/material';

function SuccessOrder() {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ width: 1, height: '100vh' }}
    >
      <ListItem>
        <Typography variant="h1">Order Successful</Typography>
      </ListItem>

      <ListItem>
        <Typography variant="h6">
          Please check your email to pay the invoice for the order. If you
          ordered a parent ad, you will be receiving an email shortly with a
          unique link to submit your parent ad materials.
        </Typography>
      </ListItem>
    </Stack>
  );
}

export default SuccessOrder;
