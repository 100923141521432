import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import Header from 'src/components/Header';
import { RootStateType } from 'src/redux/store';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

function Home() {
  const bioformDeadline = useSelector(
    (state: RootStateType) => state.constants.bioformDeadline,
  );
  const registerOrderDeadline = useSelector(
    (state: RootStateType) => state.constants.registerOrderDeadline,
  );
  const candidsDeadline = useSelector(
    (state: RootStateType) => state.constants.candidsDeadline,
  );
  const orgBioformDeadline = useSelector(
    (state: RootStateType) => state.constants.orgBioformDeadline,
  );

  return (
    <>
      <Header />

      <Box
        sx={{
          width: '100%',
          pt: 4,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1">
          Harvard Yearbook Publications Forms
        </Typography>
      </Box>

      <Box
        sx={{
          pl: '10%',
          width: '80%',
          pt: 4,
          display: 'grid',
        }}
      >
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Welcome to the Harvard Yearbook Publications Forms website. This
              website is where you can submit all of the forms related to the
              Harvard Yearbook Publications and sign up to get email
              notifications from us as well regarding any of the yearbook
              deadlines. We want to ensure that everyone is represented in the
              yearbook, as the yearbook is about the entire class, not just a
              select few people that paid attention to the deadlines. As such,
              we actively encourage you to monitor for any emails that come from
              us and pay attention to any deadlines that are coming up! We try
              our best to not spam you with emails and will keep deadlines and
              notices succinct in our emails.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              If you have any questions or concerns at any point, please reach
              out to our Business Team at{' '}
              <a href="mailto:inquiries@harvardyearbook.com">
                inquiries@harvardyearbook.com
              </a>{' '}
              and we will get back to you when one of our staff members are in
              office. Please note that as an entirely student-ran organization,
              we may not be able to get back to your email outside of office
              hour times and ask that if you have any questions, please send the
              email earlier rather than later.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Below are all of the forms that are available as well as their due
              date. Please note that these due dates are not flexible, as
              delaying any one due date runs the risk of delaying the production
              of the entire yearbook.
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">Senior Bioforms</Typography>
                <Typography variant="body1">
                  Due Date: {dayjs(bioformDeadline).format('MMMM Do, YYYY')}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" href="/yearbook_bioform" target="_blank">
                  Access Form
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">Freshman Register Bioform</Typography>
                <Typography variant="body1">
                  Due Date:{' '}
                  {dayjs(registerOrderDeadline).format('MMMM Do, YYYY')}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" href="/freshman_register" target="_blank">
                  Access Form
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">Senior Candids</Typography>
                <Typography variant="body1">
                  Due Date: {dayjs(candidsDeadline).format('MMMM Do, YYYY')}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" href="/" target="_blank">
                  Access Form
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">
                  Student Organization Bioforms
                </Typography>
                <Typography variant="body1">
                  Due Date: {dayjs(orgBioformDeadline).format('MMMM Do, YYYY')}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  href="/organization_bioform"
                  target="_blank"
                >
                  Access Form
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Home;
