export const harvardActivitiesList = [
  'Access Health',
  'Act On A Dream',
  'Advocating Success for Kids',
  'Africa Business and Investment Club [HABIC]',
  'African Students Association [HASA]',
  'Air Force ROTC',
  'Alpha Omega',
  "Alzheimer's Buddies",
  'An Evening with Champions',
  'Anime Society',
  'Anscombe Society',
  'Applied Mathematics Society',
  'Armenian Students Association',
  'Army ROTC',
  'Asian American Association [AAA]',
  'Asian American Brotherhood [AAB]',
  'Asian American Christian Fellowship [AACF]',
  'Asian American Dance Troupe [AADT]',
  "Asian American Women's Association [AAWA]",
  'Asian Baptist Student Koinonia [ABSK]',
  'Asian Student Arts Project',
  'Association Cultivating Inter-American Democracy [HACIA Democracy]',
  'Boardgame and Biosatellite Launch Enterprise (HUBBLE)',
  'Association for U.S.-China Relations [HAUSCR]',
  'ABHW [Association of Black Harvard Women]',
  'Association of Practice and Learning of Yan Xin Life Science and Technology',
  'Astrophysical Society',
  'Athena Conference',
  'Australian Undergraduate Society [AUS]',
  'Bach Society Orchestra [BachSoc]',
  'Backgammon Society',
  "Baha'i Association",
  'Ballet Company',
  'Ballet Folklorico de Aztlan',
  'Ballroom Dance Team [HBDT]',
  'Baroque Chamber Orchestra',
  'Beekeepers',
  'BGLTQ Business Society [HUBBS]',
  'Bhangra',
  'Bioethics Society',
  'Biomedical Engineering Society [BMES]',
  'Biotechnology Club',
  "Black Men's Forum [BMF]",
  'Black Pre-Law Association [BPLA]',
  'Black Students Association [BSA]',
  'BlackC.A.S.T.',
  'Undergraduate Blockchain Club',
  'Bolivian Association',
  'Brattle Street Chamber Players',
  'Brazilian Association [HUBA]',
  'Breakers',
  'British Club',
  'Bulgarian Club',
  'BWISE: BSC Fellows for a Whole Integrated Student Experience',
  'Callbacks',
  'Camp Kesem',
  'Canadian Club',
  'Cancer Society',
  'Candela Dance Troupe',
  'Caribbean Club',
  'Catholic Student Association [CSA]',
  'Chado Society',
  'Chemistry Club',
  'Chess Club',
  "Children's Stories",
  'China Forum [HCCF]',
  'Chinese Music Ensemble [HCME]',
  'Chinese Students Association [CSA]',
  'Christian Impact',
  'Christians on Campus',
  'Circle of Women',
  'CityStep',
  'Classics Club',
  'Climbing Wall',
  'Coaches',
  'Coalition for East African Peace',
  'Code Orange',
  'College Bowl',
  'College Events Board',
  'Collegium',
  'Colombian Students Association',
  'Community Garden',
  'Community of Humanists, Atheists, and Agnostics',
  'Composers Association',
  'Computer Society [HCS]',
  'Concilio Latino',
  'Consent Advocates and Relationship Educators [CARE]',
  'Conservation Society',
  'Consulting Group [HCCG]',
  'Consulting on Business and the Environment',
  'Contact Peer Counseling',
  'Convrgency',
  'Crimson Care Collaborative',
  'Crimson Commons',
  'Crimson Dance Team',
  'Crimson Key Society [CKS]',
  'CrimsonEMS',
  'Crunch Magazine',
  'Cuban-American Undergraduate Student Association [CAUSA]',
  'Cube Club',
  'Culinary Club',
  'Dancing to Heal',
  'Data Ventures',
  'Debate Council',
  'Debating Union',
  'Deepam',
  'Democrats',
  'Developers for Development',
  'Think Tank',
  'Dharma',
  'Digital Literacy Project',
  'Din and Tonics',
  'DirecTutor',
  'Disability Alliance',
  'Dramatic Club [HRDC]',
  'DREAM',
  'Dreamporte',
  'Drug and Alcohol Peer Advisors [DAPA]',
  'Eating Concerns Hotline and Outreach [ECHO]',
  'Ecdysis',
  'Ecomarathon Team',
  'Economics Association',
  'Effective Altruism',
  'Egyptian Student Association',
  'Electronic Music Collective',
  'Eleganza',
  'Emerging Scholars Program',
  'Engineering Society [HCES]',
  'Engineers Without Borders [EWB]',
  'Entertainment Society',
  'Entrepreneurship Forum',
  'Episcopal Students',
  'Eritrean-Ethiopian Students Association',
  'Esports Association',
  'European Business Group',
  'European Society',
  'Experimental Theater Ensemble',
  'Expressions',
  'Faith and Action [HCFA]',
  'Fallen Angels',
  'Fed Challenge',
  'Fig. Magazine',
  'Film Festival',
  "Filmmaker's Association",
  'Financial Analysts Club',
  'PRIMUS',
  'First-Year Arts Program [FAP]',
  'First-Year International Program [FIP]',
  'First-Year Outdoor Program [FOP]',
  'First-Year Retreat and Experience [FYRE]',
  'First-Year Social Committee [FYSC]',
  'First-Year Urban Program [FUP]',
  'Flute Ensemble',
  'Food Lab for Kids',
  'Food Literacy Project',
  'Foundation for International Medical Relief of Children [FIMRC]',
  'Francophone Society',
  'Franklin Fellowship',
  'Friends of Chabad',
  'Friends of Project Sunshine',
  'Friends of Scouting',
  'Fuerza Latina',
  'FUSIAN',
  'Future Surgeons',
  'G-Chat: First-Year Discussion Group',
  'Gaongil: Modern Korean Studies Society',
  'Gap Year',
  'Gender Inclusivity in Math',
  'Geological Society',
  'German Club',
  'Gilbert and Sullivan Players [HRG&SP]',
  'Glee Club',
  'Global Health and AIDS Coalition',
  'Global Health Forum [HUGHF]',
  'Go Club',
  'Green Medicine Initiative',
  'HackHarvardCollege',
  "Half Asian People's Association [HAPA]",
  'Harvard Book Review',
  'Harvard Economics Review',
  'Harvard College in Asia Program [HCAP]',
  'Harvard Health Policy Review',
  'Harvard Human Rights Review',
  'Harvard International Review',
  'Harvard Political Review [HPR]',
  'Harvard Review of Environment and Society',
  'Harvard Science Review',
  'Harvard Student Agencies [HSA]',
  'Undergraduate Capital Partners [HUCP]',
  'Undergraduate Union of Mixed Students [HUUMS]',
  'University Band',
  'Harvard Yearbook Publications',
  'Orchestra [HRO]',
  'Hasty Pudding Theatricals [HPT]',
  'HBASIS [Harvard College Bisexual, Gay, Lesbian, Transgender, Queer & Allied Students in the Sciences]',
  'Healing Thoughts',
  'Health Advocacy Program [HAP]',
  'Health Leads',
  'Healthcare Associates',
  'HealthPALs [Health Peer Advisors & Liaisons]',
  'Hellenic Society',
  'High School CityServe',
  'High-Tech and Business Group',
  'Hillel',
  'History Club',
  'Holoimua O Hawaii',
  'Hong Kong Society',
  'Honor Council',
  'House and Neighborhood Development [HAND]',
  'Human Powered Vehicle Team',
  'Human Rights in North Korea [HRiNK]',
  'Humanities Initiative',
  'Hygiene Campaign Club',
  'Hyperion Shakespeare Company',
  'IDENTITIES Fashion Show',
  'iGEM',
  'Immediate Gratification Players [IGP]',
  'Impact Investing Group',
  'Indigo Peer Counseling',
  'Inside Voices Step Team',
  'Institute of Politics [IOP]',
  'Intercollegiate Model United Nations',
  'Interfaith Forum',
  'International Negotiation Program',
  'International Relations Council [IRC]',
  'International Relations on Campus',
  "International Women's Rights Collective [IWRC]",
  'Into The Hub',
  'Investment Association [HIA]',
  'Iranian Association',
  'Islamic Society [HIS]',
  'Israel Public Affairs',
  'Italian Society',
  'Ivy Council',
  'Japan Initiative',
  'Japan Society',
  'Jazz Bands',
  "Jewish Women's Group",
  'John Adams Society',
  'Kali Praxi',
  'KeyChange',
  'Khmer Student Association',
  'Kidney Disease Screening and Awareness Program [KDSAP]',
  'Korean Adoptee Mentorship Program',
  'Korean Association [KA]',
  'Korean International Student Association [KISA]',
  'Kuumba Singers',
  'La Organizacion de Puertorriquenos',
  'Latinas Unidas',
  "Latino Men's Collective [LMC]",
  'Latinos in Health Careers',
  'Latinxs in Finance and Technology',
  'Latter-day Saint Student Association [LDSSA]',
  'Law Society',
  'Leadership Institute [LIHC]',
  'Libertarian Club',
  'Lowell House Opera Society',
  'LowKeys',
  "Magicians' Society",
  'MakeHarvard',
  'Manifesta Magazine',
  'Mariachi Veritas',
  'Mathematics Association [HUMA]',
  'Medical Humanities Forum',
  'Meditation Club',
  'Mentors for Urban Debate',
  'MIHNUET [Music in Hospitals and Nursing Homes Using Entertainment as Therapy]',
  'Mirch',
  'Mock Trial',
  'Model Congress [HMC]',
  'Model Congress Asia',
  'Model Congress Europe [HMCE]',
  'Model Congress Latin America [MCLA]',
  'Model Congress Middle East',
  'Model Congress San Francisco',
  'Model Security Council [MSC]',
  'Model United Nations [HMUN]',
  'Modern Dance Company [HRMDC]',
  'Mountaineering Club',
  'Mozart Society Orchestra [MSO]',
  'Musical Theater',
  'National Model United Nations [HNMUN]',
  'NAHC [Native Americans at Harvard College]',
  'Naturalist Club',
  'Navy ROTC',
  'Nepali Students Association [NeSA]',
  'Network of Enlightened Women',
  'Nigerian Students Association [NSA]',
  'Noteables',
  'Ocean Sciences Club',
  'Omo Naija',
  'On Harvard Time [OHT]',
  'On Thin Ice [OTI]',
  'Open Campus Initiative',
  'Open Data Project',
  'Open Philosophy Organization',
  'Opera Society',
  'Opportunes',
  'Organ Society',
  'Organization for Latin America [HOLA]',
  'Organization of Asian American Sisters in Service',
  'Orthodox Christian Fellowship',
  'Outing Club',
  'Pakistan Student Association',
  'Palestine Solidarity Committee [PSC]',
  'Pan-African Dance and Music Ensemble [PADAME]',
  'Partners in Health Engage',
  'Passus Step Team',
  'Pediatric Pals',
  'Peer Advising Fellow [PAF]',
  'Philippine Forum',
  'Phillips Brooks House Association  [PBHA]',
  'Phillips Brooks House Association (1-2-1 Boston Refugee Youth Enrichment) [PBHA]',
  'Phillips Brooks House Association (Alternative Spring Breaks) [ASB] [PBHA]',
  "Phillips Brooks House Association (Artists for Alzheimer's) [PBHA]",
  'Phillips Brooks House Association (Best Buddies) [PBHA]',
  'Phillips Brooks House Association (Boston Refugee Youth Enrichment Extension) [BRYE] [PBHA]',
  'Phillips Brooks House Association (Boston Refugee Youth Enrichment Summer Program) [BRYE Summer] [PBHA]',
  'Phillips Brooks House Association (Boston Refugee Youth Enrichment Teen) [BRYE Teen] [PBHA]',
  'Phillips Brooks House Association (Boston Refugee Youth Enrichment Tutoring) [BRYE] [PBHA]',
  'Phillips Brooks House Association (Boston Refugee Youth Enrichment) [BRYE Term] [PBHA]',
  'Phillips Brooks House Association (Cambridge 1-2-1) [PBHA]',
  'Phillips Brooks House Association (Cambridge Afterschool Program) [CASP] [PBHA]',
  'Phillips Brooks House Association (Cambridge Youth Enrichment Program) [CYEP] [PBHA]',
  'Phillips Brooks House Association (CHANCE) [College High-School Alliance - A Nexus for Creative Education] [PBHA]',
  'Phillips Brooks House Association (Chinatown Adventure) [CHAD] [PBHA]',
  'Phillips Brooks House Association (Chinatown Afterschool Program) [CHTNASP] [PBHA]',
  'Phillips Brooks House Association (Chinatown Big Sibling Program) [PBHA]',
  'Phillips Brooks House Association (Chinatown Citizenship Program) [PBHA]',
  'Phillips Brooks House Association (Chinatown ESL Program) [PBHA]',
  'Phillips Brooks House Association (Chinatown Teen Program) [PBHA]',
  'Phillips Brooks House Association (CIVICS) [PBHA]',
  'Phillips Brooks House Association (Connelly Center Youth Prison Tutoring Program) [PBHA]',
  'Phillips Brooks House Association (David Walker Scholars) [DWS] [PBHA]',
  'Phillips Brooks House Association (Deaf Awareness Club) [DAC] [PBHA]',
  'Phillips Brooks House Association (Elderly 1-2-1) [PBHA]',
  'Phillips Brooks House Association (Emerging Literacy Program) [HELP] [PBHA]',
  'Phillips Brooks House Association (EnviroEd) [PBHA]',
  'Phillips Brooks House Association (Environmental Action Committee) [EAC] [PBHA]',
  'Phillips Brooks House Association (Experimentors) [PBHA]',
  'Phillips Brooks House Association (Franklin Afterschool Enrichment) [FASE] [PBHA]',
  'Phillips Brooks House Association (Franklin I-O Summer Program) [FIO] [PBHA]',
  'Phillips Brooks House Association (Franklin Teen Mentoring Program) [PBHA]',
  'Phillips Brooks House Association (Habitat for Humanity) [PBHA]',
  'Phillips Brooks House Association (HARMONY) [Harvard and Radcliffe Musical Outreach to Neighborhood Youth] [PBHA]',
  'Phillips Brooks House Association (HSHS) [Harvard Square Homeless Shelter] [PBHA]',
  'Phillips Brooks House Association (Keylatch Afterschool Program) [KASP] [PBHA]',
  'Phillips Brooks House Association (Keylatch Mentor Program) [PBHA]',
  'Phillips Brooks House Association (Keylatch Summer Program) [KSP] [PBHA]',
  'Phillips Brooks House Association (Kids with Special Needs Achievement Program) [KSNAP] [PBHA]',
  'Phillips Brooks House Association (King School Buddies) [PBHA]',
  'Phillips Brooks House Association (LEADERS!) [PBHA]',
  'Phillips Brooks House Association (Mission Hill Afterschool Program) [MHASP] [PBHA]',
  'Phillips Brooks House Association (Mission Hill Summer Program) [MHSP] [PBHA]',
  'Phillips Brooks House Association (Mission Mentor) [PBHA]',
  'Phillips Brooks House Association (Native American Youth Enrichment Program) [NAYEP] [PBHA]',
  'Phillips Brooks House Association (Organization for Prison Education and Reform) [HOPE] [PBHA]',
  'Phillips Brooks House Association (Partners Empowering Neighborhoods) [PEN] [PBHA]',
  'Phillips Brooks House Association (Peer Health Exchange) [PHE] [PBHA]',
  'Phillips Brooks House Association (Pets as Therapy) [PBHA]',
  'Phillips Brooks House Association (Prison Education- Houston House Program) [PBHA]',
  'Phillips Brooks House Association (Project Literacy) [PBHA]',
  'Phillips Brooks House Association (Recent Immigrant Term-Time Enrichment) [RITE] [PBHA]',
  'Phillips Brooks House Association (Refugee Youth Summer Enrichment) [RYSE] [PBHA]',
  'Phillips Brooks House Association (Roxbury Youth Initiative Term) [RYIT] [PBHA]',
  'Phillips Brooks House Association (Roxbury Youth Initiative) [RYI] [PBHA]',
  'Phillips Brooks House Association (Small Claims Advisory Service) [SCAS] [PBHA]',
  'Phillips Brooks House Association (South Boston Afterschool Program) [SAS] [PBHA]',
  'Phillips Brooks House Association (South Boston Big Sibling Program) [PBHA]',
  'Phillips Brooks House Association (South Boston Outreach Summer) [SBOS] [PBHA]',
  'Phillips Brooks House Association (Spanish Acquisition Beginning in Elementary School) [SABES] [PBHA]',
  'Phillips Brooks House Association (STRIVE) [PBHA]',
  'Phillips Brooks House Association (Strong Women Strong Girls) [SWSG] [PBHA]',
  'Phillips Brooks House Association (Student Labor Action Movement) [SLAM] [PBHA]',
  'Phillips Brooks House Association (Student Theatre Advancing Growth and Empowerment) [STAGE] [PBHA]',
  'Phillips Brooks House Association (Suffolk Prison Education) [PBHA]',
  'Phillips Brooks House Association (Summer Science) [PBHA]',
  'Phillips Brooks House Association (Undergraduate Legal Committee) [PBHA]',
  'Phillips Brooks House Association (Wilderness 1-2-1 Program) [PBHA]',
  'Phillips Brooks House Association (Young Adults Uniting to End Homelessness) [Y2Y] [PBHA]',
  'Phillips Brooks House Association (Youth Leadership Initiative) [HYLI] [PBHA]',
  'Phillips Brooks House Association (Youth Recreation Program) [PBHA]',
  'Phillips Brooks House Association (Junior Leaders in Communities) [PBHA] ',
  'Photography Club',
  'Piano Society',
  'Polish Society',
  'Pop Culture Club',
  'Pops Orchestra',
  'Pre-Medical Society',
  'Pre-Veterinary Society',
  'Program for International Education [HPIE]',
  'Progressive Jewish Alliance [PJA]',
  'Project for Asian and International Relations [HPAIR]',
  'Project for Justice',
  'Project SWIM Club',
  'Quad Sound Studios [QSS]',
  'Quantitative Trading Club',
  'Quantum Computing Association',
  'Queer Students Association [QSA]',
  'Radcliffe Choral Society [RCS]',
  'Radcliffe Pitches',
  'Radcliffe Union of Students [RUS]',
  'Raising Autism Awareness! [HURAA!]',
  'REACH [Recreational Experience and Arts Creativity with Harvard]',
  'Red Cross',
  'Reprojustice Action and Dialogue Collective',
  'Republican Club',
  'Reserve Officer Training Corps Association [HROTCA]',
  'Response',
  'Right to Life',
  'River Charles Ensemble',
  'Road to Recovery',
  'Robotics Club',
  'Romanian Association',
  'Room 13',
  'Rootstrikers',
  'Running Club',
  'Rural Health Association',
  'Russian Speakers Association',
  'Satire V',
  'Scholars at Risk',
  'School of Rock',
  'Science Club for Girls',
  'Science Fiction Association [HRSFA]',
  'Science Olympiad [HUSO]',
  'Science Theater [ST@HC]',
  'Scientista',
  'Senior Class Committee',
  'Seventh-day Adventist Fellowship [HCSDAF]',
  'Sexual Health Awareness and Relationship Communication Educators [SHARC]',
  'SHEATH [Sexual Health Education & Advocacy throughout Harvard College]',
  'SHADE',
  'Sikh Student Association',
  'Simplicissimus',
  'Singaporean, Indonesia, and Malaysia Association [SIAMA]',
  'Slav Squad',
  'Smart Woman Securities [SWS]',
  'Social Enterprise Association [HCSEA]',
  'Social Innovation Collaborative',
  'Society for Mind, Brain, and Behavior [HSMBB]',
  'Society for the Cinematic Arts',
  'Society of Arab Students',
  'Society of Black Scientists and Engineers [HSBSE]',
  'Society of Hispanic Professional Engineers',
  'Society of Physics Students [SPS]',
  'Society of Russian Bell Ringers',
  'Society of Women Engineers',
  'SoulFood Christian Fellowship',
  'South African Society',
  'South Asian Association [SAA]',
  'South Asian Dance Company',
  "South Asian Men's Collective [SAMC]",
  "South Asian Women's Collective",
  'South Slavic Society',
  'Speak Out Loud [SOL]',
  'Special Olympics',
  'Sports Analysis Collective',
  'Sports Marketing Club',
  'Springboard Design',
  'Stand-Up Comic Society [HCSUCS]',
  'Stem Cell Society',
  'Story-Time Players',
  'Student Art Collective',
  'Student Astronomers [STAHR]',
  'Student for Myanmar',
  'Student Theater Advancing Growth and Empowerment [S.T.A.G.E.]',
  'Student-Athlete Advisory Committee [SAAC]',
  'Students for Israel',
  'Students for the Exploration and Development of Space [SEDS]',
  'Swiss Club',
  'Symphony Society',
  'Synthesis Journal',
  'Syrian Humanitarian League',
  'Taiwan Leadership Conference',
  'Taiwanese Cultural Society [TCS]',
  'TAMID',
  'Tap Company',
  'Task Force for Asian American Progressive Advocacy and Studies (TAPAS)',
  'Team HBV',
  'TEATRO!',
  'TEDxHarvardCollege',
  'Tempus',
  'Texas Club',
  'Thai Society',
  'The Aleph',
  'The Fleur-de-Lis Club (FDL)',
  'The Advocate',
  'The Harvard Crimson',
  'The Harvard Ichthus',
  'The Harvard Independent',
  'The Krokodiloes',
  'The Harvard Lampoon',
  'The Harvard Review of Philosophy',
  'The Harvard Undergraduate Research Journal [THURJ]',
  'The Ivy',
  'The K. Society (KS)',
  'The Oak Club',
  'The Sab Club',
  'The Seneca, Inc.',
  'Kappa Alpha Theta',
  'Three Letter Acronym: Harold Team [TLA]',
  'THUD [The Harvard Undergraduate Drummers]',
  'Tuesday Magazine',
  'Turkish Student Association',
  'Under Construction',
  'Undergraduate Alumni Connection [HUAC]',
  'Undergraduate Art Club',
  'Undergraduate Association [HUA]',
  'Undergraduate Fellowship [HUF]',
  'Undergraduate Research Association [HCURA]',
  'Under the Surface',
  'UNICEF Club',
  'United World Club',
  'University Choir',
  'US-India Initiative',
  'Vegetarian Society [Vegitas]',
  'Ventures [Harvard College]',
  'Veritas Financial Group [VFG]',
  'Veritones',
  'Veritutor Writing Helpline',
  'Video Game Development Club',
  'Vietnamese Association',
  'VISION',
  "Voice Actors' Guild",
  'Voters Choose',
  'WHRB [Harvard Radio Broadcasting]',
  'Wind Ensemble',
  'Wine Society',
  'Wireless Club',
  'Wisconsin Club [H-COW]',
  'Women in Business [HUWIB]',
  'Women in Computer Science',
  'Women in Nature Network',
  'Women in Science [WISHR]',
  'Women of Color Collective [WoCC]',
  "Women's Leadership Project [WLP]",
  'Woodbridge International Society',
  'World Model United Nations [WorldMUN]',
  "Writers' Workshop",
  'Writing and Public Service Initiative',
  'Yard Athletics Council [YAC]',
  'Youth Alliance for Leadership and Development in Africa',
  'YouthGlobe',
  'South Asian Americans in Public Service (SAAPS)',
  'Sailing Club',
  'Bipartisan Solutions',
  'Fishing Club',
  'Premedical Society',
  'Black Premedical Society',
  'Synapse Chapter',
  'Partners for Action in Africa',
  'Harvard Undergraduate Law Review',
  "Global Women's Empowerment",
  'Black Health Advocates',
  "Green '21",
  'Ethics Bowl',
  'Undergraduates for Environmental Justice (HUEJ)',
  'Polo Club',
  'Students for Biden ',
  "Men's Basketball Team",
  'Aeronautics',
  'Slavic Finance and Technology Association',
  'The Edible',
  'The Concordium',
  'Honor Council',
  'Real Estate Club',
  'Interdisciplinary Immunology Club',
  'Phillips Brooks House Association',
  'Women in Law Association',
  'Poker Club',
  'Olveczky Lab Organismic and Evolutionary Biology',
  'Metalab',
  'Studio Art Society',
  'Undergraduate Global Education Movement',
  'Albanian Students Association',
  'Students for Bernie',
  'Psychedelics Club',
  'Humans of Harvard College',
  'Association for U.S.-China Relations',
  'Franklin Fellowship',
  'Foreign Policy Initiative (HUFPI)',
  'VoxJazz',
  'Athletics Black Varsity Association',
  'Greener Scott Scholars Mentorship Program',
  'Undergraduate Chapter for RARE',
  'Undergraduate Women of Harvard Athletics',
  'Undergraduate Clean Energy Group (HUCEG)',
  'Overdose Prevention and Education Students (HCOPES)',
  'Undergraduate Dominican Student Association',
  'Undergraduate United Nations Association',
  'Undergraduate Cybersecurity Club',
  'Minority Association of Premedical Students (MAPS)',
  "Undergraduate Songwriters' Collective",
  'Generational African American Students Association (GAASA)',
  'Undergraduate Veterans Organization',
  'Undergraduate Alexander Hamilton Society',
  'Undergraduate Global Alliance for Medical Innovation (GAMI)',
  'Lowell House Society of Russian Bell Ringers',
  'Undergraduate World Pre-Health Conference',
  'Undergraduate Linguistics Society',
  'Undergraduate Economics Association (HUEA)',
  'Undergraduate Technology Review',
  'RAZA',
  'Global Research and Consulting Group',
  'French Club',
  'Undergraduate Design Collective',
  'Student Chapter of Storywish',
  'Signet Society',
  'German Students Association',
  'Undergraduate Chapter of University Blood Initiative',
  'Athlete Ally Chapter',
  'Student Chapter of the Massachusetts Menstrual Equity Coalition',
  'Undergraduate Initiative for Technology and Society',
  'Active Minds Student Chapter at CAMHS',
  'Undergraduate Obstacle Course Racing Club',
  'Group for Undergraduate Students in Statistics',
  'Undergraduate Patient Support Corps',
  'Undergraduate Women in Medicine',
  'Undergraduate Rock, Paper, Scissors Club',
  'Undergraduate Democracy Matters',
  'Undergraduate Art History Society',
  'Undergraduate Veterans Organization',
  'MEDLIFE- Student Chapter',
  'Undergraduate Disability Justice Club',
  'Undergraduates of New Zealand',
  'Creative Writing Collective',
  'Undergraduate Arts & Crafts Club ',
  'Undergraduate Knitting Circle',
  'Undergraduate Automotive Society',
  'Undergraduate Chapter of Scholars of Finance',
  'Undergraduate Group Peer Therapy (HUGPT)',
  'Datamatch',
  'Young Democratic Socialists of America',
  'Student Data Scientists',
  'Bengali Association of Students',
  'Constitutional Law Society',
  'The Wellness Educators',
  'Undergraduate Venture Capital Group',
  'Undergraduate Women in Foreign Policy',
  'Undergraduate Data Analytics Group',
  'Undergraduate Puzzle Club',
  'Undergraduate Machine Intelligence Community (HUMIC)',
  'Undergraduate Minecraft Club',
  'PALABRITAS',
  'Undergraduate Gaming Group (HUGG)',
  'Ghungroo',
  'Neuroscience Undergraduate Society',
  'Tibetan Cultural Association',
  'Undergraduate Alternative Investment Club',
  'AR/VR Developers',
  'Emerging Technology Group',
  'Undergraduate Biomedical Sciences Forum',
  'Open Data Project',
  'Gap Year Society',
  'Undergraduate Sports Lab',
  'Salon for the Sciences and Humanities',
  'South Asians for Forward-Thinking Advocacy and Research [SAFAR]',
  "Arab Women's Collective",
  'American Lung Cancer Screening Initiative [HCSALSCI]',
  'Maarga',
  'Behavioral Strategy Group',
  'Latina Empowerment and Development',
  'Charles River Economics Labs',
  'Women Entrepreneurship',
  'South Asian Music Association',
  'Leadership Institute for the First-Year Experience',
  "Alnisa's Medical Alliance",
  'Service with a Name',
  'Pre-Dental Society',
  'Harvard College Georgian Student Association',
  'Harvard Undergraduate Contemporary Collective',
  'Harvard Undergraduate Pole Dancing Club',
];
